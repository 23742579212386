.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-picker {
    width: 100%;
}

.form-container {
    padding-left: 1em;
    padding-right: 1em;
}

.example {
    padding-left: 1em;
    padding-right: 1em;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.ant-collapse {
    font-size: 15px;
}

pre {
    margin-top: -1em;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.ant-modal-body {
    padding: 10px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}

/* .ant-modal-header { */
/*     padding: 8px 24px; */
/*     color: rgba(0, 0, 0, 0.85); */
/*     background: #fff; */
/*     border-bottom: 1px solid #f0f0f0; */
/*     border-radius: 2px 2px 0 0; */
/* } */
